<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <label> <span @click='cancel()' class="text-capitalize cursor-pointer text-gray-900 font-semibold">
          Discount</span> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> Add New</span>
      </label>
      <h4 class="text-xl capitalize">New Discount</h4>
    </div>
    <div class="tab-container">
      <div class="tab-header flex items-center space-x-4  bg-white rounded-lg shadow-sm py-3 px-4 mb-4">
        <div :class="['tab-item flex items-center', { 'text-blue-500 font-semibold': currentTab === 1 }]">
          <span
            :class="['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', { 'border-custom': currentTab === 1 }]">1</span>
          General
        </div>
        <span class="text-gray-400">»</span>
        <div :class="['tab-item flex items-center', { 'text-blue-500 font-semibold': currentTab === 2 }]">
          <span
            :class="['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', { 'border-custom': currentTab === 2 }]">2</span>
          Platform
        </div>
        <span class="text-gray-400">»</span>
        <div :class="['tab-item flex items-center', { 'text-blue-500 font-semibold': currentTab === 3 }]">
          <span
            :class="['font-bold rounded-full border p-1.5 mr-2 text-center w-9 h-9', { 'border-custom': currentTab === 3 }]">3</span>
          Detail
        </div>
      </div>

      <div class="tab-content mt-6 px-4 py-4  rounded-lg shadow-sm">
        <div v-if="currentTab === 1" :key="currentTab">
          <h5 class="text-gray-900 font-bold text-xl">Create New Discount</h5>
          <div class="flex-col w-1/2 mt-3 pr-4">
            <label class="text-gray-600 text-sm font-semibold mb-3 block">Discount Name</label>
            <input v-model="form.name" id="discount-Name" placeholder="Enter Discount Name" type="text" required
              class="bg-gray-50 py-2.5 px-2 border rounded-lg w-full"
              :class="{ 'border-red-500': isDiscountNameError, 'placeholder-red-500 bg-red-200 text-red-700': isDiscountNameError }" />
            <p v-if="isDiscountNameError" class="text-red-500 text-sm mt-1">Must be filled.</p>


            <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Campaign Name</label>
            <multiselect v-model="form.campaign" label="name" track-by="name" placeholder="Type Campaign"
              open-direction="bottom" :options="campaignSearchOptions" :searchable="true" :loading="isCampaignSearch"
              :class="[
                isCampaignNameError ? 'border-red-500 isdiscounterror bg-red-200' : 'border-gray-300',
              ]" :close-on-select="true" :options-limit="10" :multiple="false" @search-change="campaignFind">
            </multiselect>
            <p v-if="isCampaignNameError" class="text-red-500 text-sm mt-1">Must be selected.</p>
            <DatetimeCustom :timeOnlyHours="true" class="mt-3" :label="'Valid From'" v-model="form.valid_from"></DatetimeCustom>
            <DatetimeCustom :timeOnlyHours="true" class="mt-3" :label="'Valid To'" v-model="form.valid_to"></DatetimeCustom>
            <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Partner Participation (Optional)</label>
            <SelectType v-model="form.partnerParticipation" :options="partnerParticipations"
              :default-text="'Enter Partner Participation'" />
            <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Sales Recognition (Optional)</label>
            <SelectType v-model="form.salesRecognition" :options="salesRecognitions"
              :default-text="'Enter Sales Recognition'" />
            <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Vendor Participation (Optional)</label>
            <SelectType v-model="form.vendorParticipation" :options="vendorParticipations"
              :default-text="'Enter Vendor Participation'" />
            <label class="text-gray-600 text-sm font-semibold mb-3 mt-3 block">Discount Activation Status</label>
            <div>
              <button clas type="button" @click="toggleActive" :class="{
                  'bg-blue-700': form.is_active,
                  'bg-gray-300': !form.is_active
                }" class="w-12 h-6 button-active-inactive rounded-full relative transition-colors duration-200 focus:outline-none">
                              <span :class="{
                  'translate-x-6': form.is_active,
                  'translate-x-0': !form.is_active
                }" class="w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-200"></span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="currentTab === 2" :key="currentTab">
          <div class="w-1/2">
            <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Platform</label>
            <SelectType v-model="form.platforms" :isError="isPlatformError" :options="platformsOptions"
              :default-text="'Select Platform'" />
            <p v-if="isPlatformError" class="text-red-500 text-sm mt-1">Must be selected.</p>
          </div>
          <div v-if="form.platforms === 1 || form.platforms === 2">
            <div class="w-1/2 mt-3">
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Pricing Scheme</label>
              <SelectType v-model="form.pricingScheme" :options="pricingSchemeOptions" :default-text="'Select Pricing Scheme'"
                :isError="isPricingSchemeError" />
              <p v-if="isPricingSchemeError" class="text-red-500 text-sm mt-1">Must be selected.</p>
            </div>
          </div>
        </div>

        <div v-if="currentTab === 3" :key="currentTab">
          <h4 class="text-xl font-semibold">Create New Discount</h4>
          <div v-if="form.pricingScheme === 1 && form.platforms !== 4">
            <div class="flex-col w-1/2">
              <div>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Type</label>
                <SelectType v-model="form.discountType" :options="discountTypes" :disabled="true"
                  :default-text="'Select Discount Type'" />
              </div>
              <div v-if="this.form.platforms === 1">

                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Payment Gateway</label>
                <MultiselectCustom
                    v-model="form.paymentGateways" 
                    :options="paymentGatewaysOptions"
                    :isSearchEnabled="true"
                    :disabled="true"
                    :default-text="'Select Payment Gateway '" 
                    :isMultiSelectActive="true"
                />
              </div>
              <div v-else>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Payment Gateway</label>
                <MultiselectCustom
                    v-model="form.paymentGateways" 
                    :options="paymentGatewaysOptions"
                    :isSearchEnabled="true"
                    :disabled="true"
                    :default-text="'Select Payment Gateway '" 
                    :isMultiSelectActive="true"
                />
              </div>
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Rule</label>
              <SelectType
               v-model="form.discountRuleMobile" 
               :options="discountRuleMobileOptions"
               :default-text="'Select Discount Rule '" 
               :isError="isoDiscountRuleMobileError"
                />
              <p v-if="isoDiscountRuleMobileError" class="text-red-500 text-sm mt-1">Must be filled.</p>
              <div v-if="this.form.discountRuleMobile ===3 && this.form.platforms === 1">
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Tier</label>
                <multiselect v-model='form.ios_tier' 
                label='tier_code' 
                track-by='tier_code' 
                placeholder='Ios Tier'
                open-direction='bottom' 
                :options='iosTierSearchOptions' 
                :searchable='true'
                :loading='isIosTierSearch' 
                :close-on-select='true' 
                :multiple='false' 
                @search-change='iosTierFind'>
                    </multiselect>
                </div>
                <div v-if="this.form.discountRuleMobile ===3 && this.form.platforms === 2">
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Tier</label>
                <multiselect v-model='form.android_tier' 
                label='tier_code' 
                track-by='tier_code' 
                placeholder='Android Tier'
                open-direction='bottom' 
                :options='androidSearchOptions' 
                :searchable='true'
                :loading='isIosTierSearch' 
                :close-on-select='true' 
                :multiple='false' 
                @search-change='androidTierTierFind'>
                    </multiselect>
                </div>
                <div v-if="this.form.discountRuleMobile === 4 && this.form.platforms === 1 || this.form.discountRuleMobile === 4 && this.form.platforms === 2">
                  <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Lower Tier To</label>
            
                  <SelectType 
                  v-model="form.tierLevel" 
                  :options="tierLevelOptions" 
                  :default-text="'Lowering Tier Level'"
                  />
                </div>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Offer Type</label>
                <SelectType 
                v-model="form.offerType" 
                :options="offerTypes" 
                :default-text="'Offer Type'"
                :disabled="true"
                />
            </div>
          </div>

          <div v-if="form.pricingScheme === 2  && form.platforms !== 4">
            <div class="w-1/2">
              <div>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Type</label>
                <SelectType 
                v-model="form.discountType" 
                :options="discountTypes" 
                :disabled="true"
                :default-text="'Select Discount Type'" />
              </div>
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Payment Gateway</label>
              <MultiselectCustom
                    v-model="form.paymentGateways" 
                    :options="paymentGatewaysOptions"
                    :isSearchEnabled="true"
                    :disabled="true"
                    :default-text="'Select Payment Gateway '" 
                    :isMultiSelectActive="true"/>
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Rule</label>
              <SelectType 
              v-model="form.discountRuleMobile" 
              :options="discountRuleMobileOptions"
              :disabled="true"
              :default-text="'Select Discount Rule'" 
              />  
              <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Offer Type</label>
                <SelectType 
                v-model="form.offerType" 
                :options="offerTypes" 
                :default-text="'Offer Type'"
                :disabled="true"
                />
          </div>
            </div>
          <div v-if="form.platforms === 4">
            <div class="grid gap-16 grid-cols-2">
              <div>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Type</label>
                <SelectType v-model="form.discountType" :options="discountTypes" :default-text="'Select Discount Type'"
                  :isError="isDicountTypeError" />
                <p v-if="isDicountTypeError" class="text-red-500 text-sm mt-1">Must be selected.</p>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Payment Gateway</label>
            
                  <MultiselectCustom 
                  v-model="form.paymentGateways" 
                  :options="paymentGatewaysOptions.filter(payment => payment.value !== 1 && payment.value !== 15)" 
                  :isSearchEnabled="true"
                  :isMultiSelectActive="true"
                  :default-text="'Select Payment Gateway'" 
                  :isError="isPaymentGetwayError" 
                />

                <p v-if="isPaymentGetwayError" class="text-red-500 text-sm mt-1">Must be selected.</p>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">BIN Codes (Optional)</label>
                <multiselect v-model="form.binCodes" label="name" track-by="name" placeholder="Select Bin Codes"
                  open-direction="bottom" :options="binCodesOptions" :multiple="true" :searchable="true"
                  :close-on-select="false" required>
                </multiselect>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Discount Rule</label>
                <div class="grid gap-4 grid-cols-2">

                  <div>
                    <SelectType v-model="form.discountRule" 
                    :options="discountRules.filter(rule => rule.value !== 4)"
                    :default-text="'Select Discount Rule'" 
                    :isError="isDiscountRoleError" />
                    <p v-if="isDiscountRoleError" class="text-red-500 text-sm mt-1">Must be filled.</p>


                  </div>
                  <div v-if="form.discountRule">
                    <div v-if="form.discountRule === 1">
                      <input v-model="form.discountAmount" placeholder="e.g., 2000" required type="number"
                        :class="['bg-gray-50 py-2.5 px-2 border rounded-lg w-full', { 'border-red-500 bg-red-200': isDiscountAmountError }]"
                        class="bg-gray-50 py-2.5 px-2 border rounded-lg w-full" />
                      <p v-if="isDiscountAmountError" class="text-red-500 text-sm">Discount amount is required</p>

                    </div>
                    <div v-if="form.discountRule === 2">
                      <input v-model="form.discountPercentage" min="0" step="0.1" max="100" placeholder="e.g., 20"
                        required type="number" class="bg-gray-50 py-2.5 px-2 border rounded-lg w-full"
                        :class="['bg-gray-50 py-2.5 px-2 border rounded-lg w-full', { 'border-red-500 bg-red-200': isDiscountPercentageError }]" />
                      <p v-if="isDiscountPercentageError" class="text-red-500 text-sm">Discount percentage is required
                      </p>

                    </div>

                    <div v-if="form.discountRule === 3">
                      <input v-model="form.discountFlushPrice" placeholder="e.g., 2000" required type="number"
                        :class="['bg-gray-50 py-2.5 px-2 border rounded-lg w-full', { 'border-red-500 bg-red-200': isDiscountFlushPriceError }]"
                        class="bg-gray-50 py-2.5 px-2 border rounded-lg w-full" />
                      <p v-if="isDiscountPercentageError" class="text-red-500 text-sm">Discount Flush is required</p>

                    </div>
                  </div>
                </div>

              </div>
              <div>
                <label class="text-gray-600 text-sm font-semibold block mt-3 mb-3">Order Value (USD)</label>
                <div class="grid gap-4 grid-cols-2">
                  <div>
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Minimum Order</label>
                    <input v-model.number="form.minUSDPrice" type="number" step="any" placeholder="Enter Minimum Order"
                      :min="0" @update="showPreviewFormat($event, 'minUsd', 'usd')"
                      class="bg-gray-50 py-2 px-2 border rounded-lg w-full" required />
                    <small id="formatMinUsd" class="form-text text-muted"> {{ this.previewFormat.minUsd }}</small>
                  </div>
                  <div>
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Maximum Order</label>
                    <input v-model.number="form.maxUSDPrice" type="number" step="any"
                      class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Enter Maximum Order" :min="0"
                      @update="showPreviewFormat($event, 'maxUsd', 'usd')" required />
                    <small id="formatMaxUsd" class="form-text text-muted"> {{ this.previewFormat.maxUsd }}</small>
                  </div>
                  <div>
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-3">Order Value (IDR)</label>
                    <div>
                      <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Minimum Order</label>
                      <input v-model.number="form.minIDRPrice" type="number" step="any"
                        class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Enter Minimum Order" :min="0"
                        @update="showPreviewFormat($event, 'minIdr', 'idr')" required />
                      <small id="formatMaxIdr" class="form-text text-muted"> {{ this.previewFormat.minIdr }}</small>
                    </div>
                  </div>
                  <div class="mt-9">
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Maximum Order</label>
                    <input v-model.number="form.maxIDRPrice" type="number" step="any"
                      class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Enter Maximum Order" :min="0"
                      @update="showPreviewFormat($event, 'maxIdr', 'idr')" required />
                    <small id="formatMaxIdr" class="form-text text-muted"> {{ this.previewFormat.maxIdr }}</small>
                  </div>
                </div>
                <div class="flex-col justify-start items-start">
                  <div>
                    <label class="text-gray-600 text-sm font-semibold block mt-0 mb-2">Offer Type</label>
                    <SelectType v-model="form.offerType" :options="offerTypes" :default-text="'Offer Type'"
                      :isError="isofferTypeError" />
                    <p v-if="isofferTypeError" class="text-red-500 text-sm mt-1">Must be filled.</p>

                  </div>
                  <div v-if="form.offerType === 2">
                    <label class="text-gray-600 text-sm font-semibold block mt-3 mb-2">General Offer </label>
                    <SelectType v-model="form.generalOffer" :options="generalOffers" :isError="isGeneralOfferError"
                      :default-text="'Select General Offer'" />
                    <p v-if="isGeneralOfferError" class="text-red-500 text-sm mt-1">Must be filled.</p>

                  </div>
                  <div label="Publisher" v-if="form.generalOffer == 10 && form.offerType === 2
                      || form.generalOffer == 11
                      && form.offerType === 2 ||
                      form.generalOffer == 12 && form.offerType === 2
                      || form.generalOffer == 13 && form.offerType === 2">
                    <label class="text-gray-600 text-sm font-semibold block mt-3 mb-2">Publisher</label>

                    <multiselect v-model="form.publisher" label="name" track-by="name" placeholder="Type Publisher"
                      open-direction="bottom" :options="publisherSearchOptions" :searchable="true"
                      :loading="isPublisherSearch" :close-on-select="true" :options-limit="10" :multiple="false"
                      @search-change="publisherFind">
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <div class="bg-white mt-6 px-4 py-4  rounded-lg shadow-sm"
        v-if="currentTab === 3 && form.offerType === 1">
        <div class="flex justify-end items-center">
          <div class="">

            <button class="border rounded-lg py-2.5 px-2.5 mb-4 bg-white" @click="addBulk"><i
                class="fa-solid fa-plus"></i> Add Bulk</button>
            <t-modal ref="AddBulkModal">
              <div class="text-sm flex-col text-left mb-3">
                <label class="font-semibold">Offer Name</label>
                <p class="text-gray-500">Enter the offer you want to include in the list.</p>
              </div>
              <t-textarea type="number" class="rounded-lg mb-4" placeholder="Eg: 708243, 708243, 708243"
                v-model="form.arrayIdOffers" @change="searchByNumberOffers"></t-textarea>
              <div class="flex justify-center">
                <button class="bg-blue-700 py-2.5 px-2.5 w-full text-white rounded-lg"
                  @click="addBulkId">Submit</button>
              </div>
            </t-modal>
          </div>
        </div>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Act</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Offer Name or
                id</th>
              <th v-if="form.pricingScheme === 2 && form.platforms === 1" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Apple Promotional Code</th>
              <th v-if="form.pricingScheme === 2 && form.platforms === 2" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Android Promotional Code</th>
             
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">original price
              </th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">After discount
                price</th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">discount
                percentage</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr>
              <td></td>
              <td></td>
              <td colspan="1" class="text-center py-3 px-4">
                <div class="flex justify-start w-full">
                  <div class="block w-96 ">
                    <button @click="addNewRow"
                      class="text-center border-custom bg-white w-full text-blue-500 font-semibold px-0 py-2 rounded-lg">
                      <i class="fa-solid fa-plus mr-2"></i> Add New
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-for="(item, index) in selectOnDataTable || []" :key="index">
              
              <td class="px-6 py-1 text-center "><i class="fa-solid fa-trash text-gray-500 cursor-pointer"
                  @click="deleteRow(index)"></i></td>
              <td class="px-6 py-1 text-center ">{{ index + 1 }}</td>
              <td class="px-6 py-1 w-96" :title="item.selectedOfferName?.name || ''">
                <multiselect 
                class="w-96 discount-offers" 
                v-model="item.selectedOfferName" 
                label="name" 
                track-by="id"
                placeholder="Enter Offer Name" 
                open-direction="bottom" 
                :options="bulkOffersSearchOptions"
                :loading="isbulkOffersSearch" 
                :searchable="true" 
                :close-on-select="true" 
                :multiple="false"
                @input="onOfferSelect($event, index)" 
                @search-change="bulkOfferFind"
              >
                <template #option="{ option, index }">
                  <div class="custom-option" :key="index"  :title="option.name || ''">
                    {{ option.name }}
                  </div>
                </template>
              </multiselect>

              
              </td>
              <td  v-if="form.pricingScheme === 2 && form.platforms === 1 || form.pricingScheme === 2 && form.platforms === 2">
                <span >
                  <input 
                v-model="item.inputPremiumCode" 
                type="text" step="any"
               @input="codePremium($event,index)"
                class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Input Code"
                 />
                </span>
              </td>
              <td class="px-6 py-1 ">
                {{ item.price_original }}
              </td>
              <td class="px-6 py-1" :class="{'text-red-500': item.price_original !== '' && item.id !== '' ? item.price_discount === 0 : ''}"  v-if="form.platforms === 4 || form.pricingScheme === 1 && form.platforms === 1 || form.pricingScheme === 1 && form.platforms === 2">
                {{ item.price_discount }}
              </td>
              <td class="px-6 py-1" v-if="form.pricingScheme === 2 && form.platforms === 1 || form.pricingScheme === 2 && form.platforms === 2">
                 <span>
                  <input 
                    v-model="item.price_discountPremium" 
                    type="number" step="any"
                    class="bg-gray-50 py-2 px-2 border rounded-lg w-full" placeholder="Input After Discount Price"
                        @input="afterDiscoundPremium($event,item,index)"
                    />
                 </span>
              </td>
              <td class="px-6 py-1 " :class="{'text-red-500': item.price_original !== null ? item.price_discountPremium === 0 : ''}" v-if="form.pricingScheme === 2 && form.platforms === 1  || form.pricingScheme === 2 && form.platforms === 2">
                <span  >
                  {{ item.discount_percentagePremium }}%
                 </span>
               </td>
               <td class="px-6 py-1" :class="{'text-red-500': item.price_original !== null && item.id !== '' ? item.price_discount === 0 : ''}" v-if="form.platforms === 4 || form.pricingScheme === 1 && form.platforms === 1 || form.pricingScheme === 1 && form.platforms === 2">
                {{ item.discount_percentage }}%
               </td>
            </tr>
           
          </tbody>
        </table>
      </div>
      <div class="tab-navigation mt-6 flex justify-start space-x-2 mb-4">
        <button v-if="currentTab === 3" @click="onSubmit" class="bg-blue-600 text-white px-4 py-2 rounded">Save</button>
        <button v-if="currentTab === 1 || currentTab === 2" @click="nextTab"
          class="bg-blue-600 text-white px-4 py-2 rounded">Next</button>
        <button  :class="{'hidden':currentTab === 1}" @click="previousTab" class="bg-white text-gray-500 px-4 py-2 rounded">Back</button>
        <button :class="{'hidden' :currentTab === 2 || currentTab === 3 }" @click="backOnListDiscount" class="bg-white text-gray-500 px-4 py-2 rounded">Back</button>
      </div>

    </div>



    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>

  </b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import constant from "../../store/constant";
import DatetimeCustom from '../../components/form/Datetime.vue'
import SelectType from '../../components/form/ContentTypeSelector.vue';
import MultiselectCustom from '../../components/form/MultiSelectedCustom.vue'
import { EventBus } from '../../../src/event-bus'
import moment from "moment";
const PARTNER_PARTICIPATION = Object.entries(constant.PARTNER_PARTICIPATION).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const DISCOUNT_TYPES = Object.entries(constant.DISCOUNT_TYPES).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});

const PAYMENT_GATEWAYS = Object.entries(constant.PAYMENT_GATEWAY).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});

const DISCOUNT_PREDEFINED_GROUP = Object.entries(constant.DISCOUNT_PREDEFINED_GROUP).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});

const BIN_CODES = constant.ACTIVE_BIN.map((item) => {
  return {
    name: item.name,
    value: item.value,
  }
});


const PLATFORM_PROMO = Object.entries(constant.PLATFORM_PROMO).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const OFFER_DISCOUNT_RULE = Object.entries(constant.OFFER_DISCOUNT_RULE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});


export default {
  name: "newDiscountOffer",
  components: {
    DatetimeCustom,
    MultiselectCustom,
    SelectType
  },
  data() {
    return {
      form: {
        campaign: "",
        name: "",
        salesRecognition: 1,
        pricingScheme: null,
        partnerParticipation: 2,
        vendorParticipation: 2,
        discountType: null,
        platforms: null,
        paymentGateways: [],
        discountRuleMobile: null,
        discountRule: null,
        discountAmount: 0,
        discountPercentage: 0,
        discountFlushPrice: 0,
        arrayIdOffers: "",
        //  trialPromotions: '0 days',
        binCodes: [],
        discount_usd: 0,
        discount_idr: 0,
        minUSDPrice: 0,
        point: 0,
        maxUSDPrice: 0,
        minIDRPrice: 0,
        maxIDRPrice: 0,
        offerType: null,
        tierLevel :0,
        ios_tier:null,
        android_tier:null,
        selectedTier:null,
        generalOffer: null,
        premiumCode:null,
        discountPremium:null,
        specifiedOffer: [],
        valid_from: moment().toString(),
        valid_to: moment().toString(),
        publisher: [],
        bulkOffers: [],
        is_active: false,
      },
     
      arrayIdOffersResult: [],
      iosTierSearchOptions: [],
      androidSearchOptions:[],
      isIosTierSearch: false,
      isAndroidTierSearch:false,
      timezone: '',
      currentTab: 1,
      selectOnDataTable: [],
      salesRecognitions: [
        { value: 1, text: 'Recognize as Normal Sales' },
        { value: 2, text: 'Recognize as other incomes' },
      ],
      pricingSchemeOptions: [
        { value: 1, text: 'Single' },
        { value: 2, text: 'Premium' },
      ],
      partnerParticipations: [
        ...PARTNER_PARTICIPATION,
      ],
      vendorParticipations: [
        ...PARTNER_PARTICIPATION,
      ],
      discountTypes: [
        ...DISCOUNT_TYPES
      ],
      platformsOptions: [
        ...PLATFORM_PROMO,
      ],

      paymentGatewaysOptions: [
        ...PAYMENT_GATEWAYS,
      ],
      discountRules: [
        ...OFFER_DISCOUNT_RULE,
      ],
      // trialPromotions: [
      //   {value: '0 days', text:' Select Trial Day'},
      //   ...TRIALS,
      // ],
      offerTypes: [
        { value: 1, text: 'Specified Offer' },
        { value: 2, text: 'General Offer' },
      ],
      tierLevelOptions:[
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
      ],
      discountRuleMobileOptions: [
        { value: 3, text: 'Flush Price' },
        { value: 4, text: 'Lowering Tier Level' },
      ],
      binCodesOptions: [
        ...BIN_CODES,
      ],
      generalOffers: [
        ...DISCOUNT_PREDEFINED_GROUP,
      ],
      previewFormat: {
        discIdr: "",
        discUsd: "",
        minUsd: "",
        maxUsd: "",
        minIdr: "",
        maxIdr: "",
      },
      show: true,
      campaignSearchOptions: [],
      isCampaignSearch: false,
      publisherSearchOptions: [],
      bulkOffersSearchOptions: [],
      isbulkOffersSearch: false,
      isPublisherSearch: false,
      debounce: null,
      isDiscountNameError: false,
      isPlatformError: false,
      isPricingSchemeError: false,
      isDicountTypeError: false,
      isCampaignNameError: false,
      isPaymentGetwayError: false,
      isDiscountRoleError: false,
      isDiscountFlushPriceError: false,
      isDiscountAmountError: false,
      isDiscountPercentageError: false,
      isofferTypeError: false,
      isGeneralOfferError: false,
      isoDiscountRuleMobileError: false,
      
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.discounts.isError,
      isLoading: (state) => state.discounts.isLoading,
      errorMessage: (state) => state.discounts.errorMessage,
      successMessage: (state) => state.discounts.successMessage,
      item: (state) => state.discounts.item,
      iosTier:(state) => state.discounts.iosTier,
      androidTier:(state) => state.discounts.androidTier
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      EventBus.$emit('show-toast', {
        message: this.errorMessage,
        type: 'error',
      });
    },

    successMessage: function () {
    if (!this.successMessage) return;

    EventBus.$emit('show-toast', {
      message: this.successMessage,
      type: 'success',
    });

    if (this.isLoading) {
      this.$store.commit('discounts/setLoading', false);
    }
  },
    "form.discountRule"() {
      this.resetDiscountFields();
      this.isDiscountRoleError = false
      
    },
    'form.name'(newValue) {
      this.isDiscountNameError = newValue.trim() === '';
    },
    'form.campaign'() {
      this.isCampaignNameError = false;
    },
    'form.platforms'() {
      this.isPlatformError = false
    },
    'form.pricingScheme'() {
      this.isPricingSchemeError = false
    },
    'form.discountType'() {
      this.isDicountTypeError = false
    },
    'form.paymentGateways'() {
      this.isPaymentGetwayError = false
    },

    'form.offerType'() {
      this.isofferTypeError = false
    },
    'form.discountAmount'() {
      this.isDiscountAmountError = false;
      this.updateDiscountPrices()
    },
    'form.discountPercentage'() {
      this.isDiscountPercentageError = false;
      this.updateDiscountPrices()
    },
    'form.discountFlushPrice'() {
      this.isDiscountFlushPriceError = false;
      this.updateDiscountPrices()
    },
    'form.generalOffer'() {
      this.isGeneralOfferError = false
    },
    'form.discountRuleMobile'() {
      this.isoDiscountRuleMobileError = false
      this.updateDiscountPrices()
    },
    'form.tierLevel'() {
      this.updateDiscountPrices()
    },
    
    'form.ios_tier'() {
      const selectedTier = this.form.ios_tier;

          if (selectedTier) {
            this.updateDiscountPrices();
          } else {
            console.log("Tier belum dipilih atau price_idr kosong");
          }
      },
      'form.android_tier'() {
      const selectedTier = this.form.android_tier;

          if (selectedTier) {
            this.updateDiscountPrices();
          } else {
            console.log("Tier belum dipilih atau price_idr kosong");
          }
      }
            
  },
  mounted() {
    this.previewFormat.discUsd = this.numberFormatUsd(this.form.discount_usd);
    this.previewFormat.discIdr = this.numberFormatIdr(this.form.discount_idr);
    this.previewFormat.minUsd = this.numberFormatUsd(this.form.minUSDPrice);
    this.previewFormat.maxUsd = this.numberFormatUsd(this.form.maxUSDPrice);
    this.previewFormat.minIdr = this.numberFormatIdr(this.form.minIDRPrice);
    this.previewFormat.maxIdr = this.numberFormatIdr(this.form.maxIDRPrice);
  },
  methods: {
    ...mapActions("campaign", ["searchCampaign"]),
    ...mapActions("discounts", [
      "discountPost",
      "searchVendors",
      "fetchDiscountsTiercodeIos",
      "fetchDiscountsTiercodeIosSearch",
      "fetchDiscountsTiercodeAndroidSearch",
      "fetchDiscountsTiercodeAndroid",
      "searchBulkyOffers",
      "searchBulkyOffersByNumber"
    ]),
    validateAmount() {
      if (this.form.discountRule == constant.OFFER_DISCOUNT_RULE['Cut by Percentage.'] && this.form.discount_idr >= 100 && this.form.discountType == 1) {
        this.messageAlert('error', 'Discount Offers cannot be 100%', 5000);
        return false;
      }
      if (this.form.discountRule == constant.OFFER_DISCOUNT_RULE['Cut by Percentage.'] && this.form.discount_usd >= 100 && this.form.discountType == 1) {
        this.messageAlert('error', 'Discount Offers cannot be 100%', 5000);
        return false;
      }
      return true;
    },

    nextTab() {
      let isValid = true;

      if (!this.form.name) {
        this.isDiscountNameError = true;
        isValid = false;
      } else {
        this.isDiscountNameError = false;
      }

      if (!this.form.campaign) {
        this.isCampaignNameError = true;
        isValid = false;
      } else {
        this.isCampaignNameError = false;
      }

      if (this.currentTab === 2) {

        if (!this.form.platforms) {
          this.isPlatformError = true;
          isValid = false;
        } else {
          this.isPlatformError = false;
        }

        if (!this.form.pricingScheme && this.form.platforms == 1 || !this.form.pricingScheme && this.form.platforms == 2) {
          this.isPricingSchemeError = true;
          isValid = false;
        } else {
          this.isPricingSchemeError = false;
        }

      }
    

    const validFromDate = new Date(this.form.valid_from);
    const validToDate = new Date(this.form.valid_to);

    if (isNaN(validFromDate.getTime()) || isNaN(validToDate.getTime())) {
      isValid = false;
      EventBus.$emit('show-toast', {
        message: 'Both Valid From and Valid To must be valid dates.',
        type: 'error',
      });
    } else if (validFromDate >= validToDate) {
      isValid = false;
      EventBus.$emit('show-toast', {
        message: 'Valid To must be greater than Valid From.',
        type: 'error',
      });
    }

      if (isValid && this.currentTab < 3) {
        if (this.form.pricingScheme === 1 && this.form.platforms === 1) {
          this.form.discountType = 1
          this.form.paymentGateways = [1]
          this.form.offerType = 1
         // this.form.discountRuleMobile = 3
          this.fetchDiscountsTiercodeIos()
        }
        if( this.form.pricingScheme === 2 && this.form.platforms === 1) {
             this.form.discountRuleMobile = 3
             this.form.discountType = 1
             this.form.paymentGateways = [1]
             this.form.offerType = 1
          }

        if (this.form.pricingScheme === 1 && this.form.platforms === 2) {
          this.form.discountType = 1
          this.form.paymentGateways = [15]
          this.form.offerType = 1
          this.fetchDiscountsTiercodeAndroid()
        }
        if( this.form.pricingScheme === 2 && this.form.platforms === 2) {
             this.form.discountRuleMobile = 3
             this.form.discountType = 1
             this.form.paymentGateways = [15]
             this.form.offerType = 1
          }

        this.currentTab++;
      }
    },
    previousTab() {
  if (this.currentTab > 1) {
    this.currentTab--;
  }
  if (this.currentTab === 2) {
    this.selectOnDataTable = []
    this.bulkOffersSearchOptions = []
  }
},
  backOnListDiscount () {
    this.$router.push({
      path: `/discounts`,
    });
  },
    goToTab(tabNumber) {
      this.currentTab = tabNumber
    },
    toggleActive() {
      this.form.is_active = !this.form.is_active;
    },
    addBulk() {
      this.$refs.AddBulkModal.show();
    },
    onSubmit(event) {
      event.preventDefault();
      const data = this.form;
    
      let isValid = true;
      if (this.currentTab === 3 && this.form.platforms === 4) {
        if (!this.form.discountType) {
          this.isDicountTypeError = true;
          isValid = false;
        } else {
          this.isDicountTypeError = false;
        }
        if (this.form.paymentGateways == false) {
          this.isPaymentGetwayError = true;
          isValid = false;
        } else {
          this.isPaymentGetwayError = false;
        }
        if (!this.form.discountRule) {
          this.isDiscountRoleError = true;
          isValid = false;
        } else {
          this.isDiscountRoleError = false;
        }

        if (!this.form.generalOffer && this.form.offerType === 2) {
          this.isGeneralOfferError = true;
          isValid = false;
        } else {
          this.isGeneralOfferError = false;
        }
        if (!this.form.offerType && this.form.specifiedOffer || !this.form.offerType && this.form.generalOffer) {
          this.isofferTypeError = true;
          isValid = false;
        } else {
          this.isofferTypeError = false;
        }

        if (this.form.discountRule === 1 && !this.form.discountAmount) {
          this.isDiscountAmountError = true;
          isValid = false;
        } else {
          this.isDiscountAmountError = false;
        }

        if (this.form.discountRule === 2 && !this.form.discountPercentage) {
          this.isDiscountPercentageError = true;
          isValid = false;
        } else {
          this.isDiscountPercentageError = false;
        }

        if (this.form.discountRule === 3 && !this.form.discountFlushPrice) {
          this.isDiscountFlushPriceError = true;
          isValid = false;
        } else {
          this.isDiscountFlushPriceError = false;
        }

        if (isValid && this.selectOnDataTable.length === 0 && this.form.offerType === 1) {
          EventBus.$emit('show-toast', {
            message: 'Please select at least 1 offer.',
            type: 'error',
          });
          isValid = false;
        }  
        if ((this.form.discountAmount || this.form.discountFlushPrice || this.form.discountPercentage) && this.selectOnDataTable.length > 0) {
        this.selectOnDataTable.forEach((item) => {
          if (!item.id) {
            isValid = false;
            EventBus.$emit('show-toast', {
              message: 'Data cannot be empty',
              type: 'error',
            });
          }
        });
      }

      }

      if( this.currentTab === 3 && this.form.platforms === 2  && this.form.pricingScheme === 2 || 
          this.currentTab === 3 && this.form.platforms === 1 && this.form.pricingScheme === 2) {
          this.selectOnDataTable.map((item) => {
          if (!item.inputPremiumCode || !item.price_discountPremium) {
            isValid = false;
            EventBus.$emit('show-toast', {
            message: 'Please ensure all offers have a promotional code and discount price.',
            type: 'error',
          }); 
          }
        });
        }

      if (this.currentTab === 3 && this.form.platforms === 2 || this.currentTab === 3 && this.form.platforms === 1) {
        if (!this.form.discountRuleMobile) {
          this.isoDiscountRuleMobileError = true;
          isValid = false;
        } else {
          this.isoDiscountRuleMobileError = false;
        }

        if (isValid && this.selectOnDataTable.length === 0 && this.form.offerType === 1) {
          EventBus.$emit('show-toast', {
            message: 'Please select at least 1 offer.',
            type: 'error',
          });
          isValid = false;
        }  
           if ((this.form.discountAmount || this.form.discountFlushPrice || this.form.discountPercentage) && this.selectOnDataTable.length > 0) {
        this.selectOnDataTable.forEach((item) => {
          if (!item.id) {
            isValid = false;
            EventBus.$emit('show-toast', {
              message: 'Data cannot be empty',
              type: 'error',
            });
          }
        });
      }
      }

      if (isValid) {
        const objectDiscountRule = [
          data.discountAmount,
          data.discountFlushPrice,
          data.discountPercentage,
        ];
        const payload = {
          name: data.name,
          campaign_id: data.campaign.id,
          sales_recognition: data.salesRecognition,
          partner_participation: data.partnerParticipation,
          vendor_participation: data.vendorParticipation,
          platforms: [data.platforms],
          discount_type: data.discountType,
          payment_gateways: data.paymentGateways,
          discount_rule: data.discountRuleMobile ?? data.discountRule,
          //  trial_time: data.trialPromotions, 
          pricing_scheme:data.pricingScheme,
          discount_tier: data.platforms === 1 ? data.ios_tier?.tier_code  : data.platforms === 2 ? data.android_tier?.tier_code : null,
          discount_idr: 
          this.form.platforms === 4 
          ? objectDiscountRule.filter(item => item !== null && item !== undefined).join(", ") || "0" 
          : data.ios_tier?.price_idr || data.android_tier?.price_idr || "0",
          bin_codes: data.binCodes.map(item => item.value),
          discount_usd: data.discount_usd ?? 0,
          min_usd_order_price: data.minUSDPrice ?? 0,
          max_usd_order_price: data.maxUSDPrice ?? 0,
          min_idr_order_price: data.minIDRPrice ?? 0,
          max_idr_order_price: data.maxIDRPrice ?? 0,
          offer_type: data.offerType,
          lower_tier: this.form.tierLevel ?? null,
          predefined_group: data.generalOffer,
          offers: this.selectOnDataTable.map((item) => ({
              offer_id: item.id,
              promotional_code: item.inputPremiumCode,
              discount_price: item.price_discountPremium,
            })),
            valid_from: (() => {
              const date = new Date(data.valid_from);
              date.setMinutes(0, 0, 0);
              return date.toISOString();
            })(),
            valid_to: (() => {
              const date = new Date(data.valid_to);
              date.setMinutes(0, 0, 0);
              return date.toISOString();
            })(),

          discount_point: data.point ?? 0,
          vendor_id: data.publisher.id,
          is_active: data.is_active
        }
        this.discountPost(payload);
      }

    },

    campaignFind(query) {
      if (!query) return;
      this.isCampaignSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCampaign(payload).then((response) => {
          this.campaignSearchOptions = response.data.data.rows;
          this.isCampaignSearch = false;
        }).catch(() => {
          this.isCampaignSearch = false;
        });
      }, 600);
    },
    publisherFind(query) {
      if (!query) return;

      this.isPublisherSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchVendors(payload).then((response) => {
          this.publisherSearchOptions = response.data.data.rows;
          this.isPublisherSearch = false;
        }).catch(() => {
          this.isPublisherSearch = false;
        });
      }, 600);
    },
    searchByNumberOffers() {
      this.arrayIdOffersResult = this.form.arrayIdOffers
        .split(",")
        .map((value) => value.trim())
        .filter((value) => !isNaN(value) && value !== "")
        .map(Number);
    },
    updateDiscountPrices() {
      this.selectOnDataTable.forEach(item => {
        let discountPercentage = 0;
        if (this.form.discountRule === 1) {

          item.id && (this.form.discount_idr = item.price_original - this.form.discountAmount);
          if (this.form.discount_idr < 0) {
            this.form.discount_idr = 0;
          }
          
          discountPercentage = item.id && ((this.form.discountAmount / item.price_original) * 100).toFixed();
          if (discountPercentage > 100) {
            discountPercentage = 100;
          }
        }


        if (this.form.discountRule === 2) {

          if (this.form.discountPercentage < 0) {
            this.form.discountPercentage = 0;
          } else if (this.form.discountPercentage > 100) {
            this.form.discountPercentage = 100;
          }
          if (this.form.discountPercentage === '' || this.form.discountPercentage === null) {
            this.form.discountPercentage = 0;
          }


          this.form.discountPercentage = parseFloat(this.form.discountPercentage);
          let percentageUsing = 100 - this.form.discountPercentage;
          this.form.discount_idr =  item.id && Math.round((percentageUsing / 100) * item.price_original);
          discountPercentage = this.form.discountPercentage;
        }
      
        if (this.form.discountRule === 3) {
          this.form.discount_idr = Number(this.form.discountFlushPrice);

          if (this.form.discount_idr < 0) {
            this.form.discount_idr = 0;
          }

          if (this.form.discount_idr > item.price_original) {
            this.form.discount_idr = item.price_original;
            this.form.isDiscountFlushPriceError = true
          }

          if (this.form.discount_idr > 0) {
            discountPercentage = item.id && ((item.price_original - this.form.discount_idr) / item.price_original * 100).toFixed();
            if (discountPercentage > 100) {
              discountPercentage = 100;
            }
          }
        }
        if(this.form.platforms !== 4) {
          item.selectedOfferName.platform_offers.forEach((offer) => {  
           
            if(this.form.discountRuleMobile === 3 && this.form.discountRuleMobile !== 4 ) {
              const iosTier = this.form.ios_tier;
              const androidTier = this.form.android_tier;

              if (iosTier && iosTier.price_idr || androidTier && androidTier.price_idr) {
              
              this.form.discount_idr = this.form.platforms === 1 ? parseFloat(iosTier.price_idr) : parseFloat(androidTier.price_idr);
              if (parseFloat(this.form.discount_idr) > parseFloat(item.price_original)) {
                EventBus.$emit('show-toast', {
                  message: 'Discount price must be lower than the normal price',
                  type: 'error',
                });
                this.form.discount_idr = 0
              }


              if (this.form.discount_idr > 0) {
                  discountPercentage = ((item.price_original - this.form.discount_idr) / item.price_original * 100).toFixed();
                if (discountPercentage > 100) {
                  discountPercentage = 100;
                }
              }
          } 
        } 
         
            if(this.form.discountRuleMobile === 4 && this.form.discountRuleMobile !== 3) {
              this.iosTier.forEach((iosTierItem) => {  
            if (offer.tier_code === iosTierItem.tier_code && offer.platform_id === 1) {    
      
              const resultCalculte = iosTierItem.level_tier - this.form.tierLevel;
              const finalResult = resultCalculte < 1 ? 1 : resultCalculte
              const objectTier = this.iosTier[finalResult - 1]
              
              this.form.discount_idr = this.form.tierLevel !== 0 ? objectTier.price_idr: objectTier.price_idr
              if (parseFloat(this.form.discount_idr) > parseFloat(item.price_original)) {
                EventBus.$emit('show-toast', {
                  message: 'Discount price must be lower than the normal price',
                  type: 'error',
                });

                this.form.discount_idr = 0
                return;

              } else {
                const discountPrice =item.price_original -  this.form.discount_idr
                const persatagePrice  =  this.form.tierLevel !== 0 ? ((discountPrice / item.price_original) * 100).toFixed() : 0
            
                if (persatagePrice === '100') {
                    discountPercentage = '0'; 
                } else {
                    discountPercentage = this.form.tierLevel !== 0 ? (item.price_original - this.form.discount_idr) / item.price_original * 100: 0
                    discountPercentage = discountPercentage.toFixed();
                }
              }    
            }
          });

          this.androidTier.forEach((androidTier) => {  
            if (offer.tier_code === androidTier.tier_code && offer.platform_id === 2) {    
              const resultCalculte = androidTier.level_tier - this.form.tierLevel;
              const finalResult = resultCalculte < 1 ? 1 : resultCalculte
              const objectTier = this.androidTier[finalResult - 1]
              this.form.discount_idr = this.form.tierLevel !== 0 ? objectTier.price_idr: objectTier.price_idr

              if (parseFloat(this.form.discount_idr) > parseFloat(item.price_original)) {
                EventBus.$emit('show-toast', {
                  message: 'Harga discount harus lebih kecil dari harga normal',
                  type: 'error',
                });
                this.form.discount_idr = 0
                return;

              } else {
                const discountPrice =item.price_original -  this.form.discount_idr
                const persatagePrice  =  this.form.tierLevel !== 0 ? ((discountPrice / item.price_original) * 100).toFixed() : 0

                if (persatagePrice === '100') {
                    discountPercentage = '0'; 
                } else {
                    discountPercentage = this.form.tierLevel !== 0 ? (item.price_original - this.form.discount_idr) / item.price_original * 100: 0
                    discountPercentage = discountPercentage.toFixed();
                }
              }    
            }
          });
            }
        
        });
     
     
        }
        item.price_discount = this.form.discount_idr;
        item.discount_percentage = discountPercentage;

      });
    },
    resetDiscountFields() {
      this.form.discountAmount = null;
      this.form.discountPercentage = null;
      this.form.discountFlushPrice = null;
      this.selectOnDataTable = []
    },
    addNewRow() {
  const newRow = {
    id: '',
    name: '',
    selectedOfferName: [],
    price_discount: 0,
    inputPremiumCode:'',
    price_discountPremium:'',
    discount_percentage: 0,
    discount_percentagePremium:0,
    created: new Date()
  };

  function isRowValid(row) {
    for (let key in row) {
      if (row[key] === '' || (Array.isArray(row[key]) && row[key].length === 0)) {
        return false;
      }
    }
    return true; 
  }
  const hasEmptyId = this.selectOnDataTable.some(row => row.id === '');
 
  if (hasEmptyId) {
    console.log('Tidak bisa menambahkan row. ID kosong atau ID duplikat.');
    return;
  }


    this.selectOnDataTable.push(newRow);
  if (isRowValid(newRow)) {
    this.selectOnDataTable.push(newRow);
    this.updateDiscountPrices()
  } 
},
    addBulkId() {
      this.searchBulkyOffersByNumber(this.arrayIdOffersResult).then((response) => {
        response.data.data.offers.forEach((offer) => {
          const existingOffer = this.selectOnDataTable.find(item => item.id === offer.id);
          if (existingOffer) {
            EventBus.$emit('show-toast', {
             message:`Offer dengan ID ${offer.id} sudah ada!`,
            type: 'error',
         });
          } else {
            const newRow = {
              id: offer.id,
              name: offer.name,
              selectedOfferName: offer,
              price_original: offer.price_idr,
              price_discount: 0,
              discount_percentage: 0
            };
            this.selectOnDataTable.push(newRow);
            this.form.arrayIdOffers = ''
          }
        
        });
        this.updateDiscountPrices()
         
        this.$refs.AddBulkModal.hide();
     
      });

    },

    bulkOfferFind(query) {
      if (!query) return;
      this.isbulkOffersSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
          platform:this.form.platforms,
          ispremium:this.form.pricingScheme === 2 ? true: false,
        };
        this.searchBulkyOffers(payload).then((response) => {
          this.bulkOffersSearchOptions = response.data.data.rows;
          this.isbulkOffersSearch = false;
        }).catch(() => {
          this.isbulkOffersSearch = false;
        });
      }, 600);
    },

    iosTierFind(query) {
      if (!query) return;
      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchDiscountsTiercodeIosSearch({ q: query }).then((response) => {
          this.iosTierSearchOptions = response.data.data;
          this.form.iosFlushDiscount = this.iosTierSearchOptions.price_idr
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },
    androidTierTierFind(query) {
      if (!query) return;
      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchDiscountsTiercodeAndroidSearch({ q: query }).then((response) => {
          this.androidSearchOptions = response.data.data;
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },

    onOfferSelect(selectedItem, rowIndex) {
      if (selectedItem) {
        this.selectOnDataTable[rowIndex].id = selectedItem.id;
        this.selectOnDataTable[rowIndex].name = selectedItem.name;
        this.selectOnDataTable[rowIndex].price_original = selectedItem.price_idr;
        this.selectOnDataTable[rowIndex].inputPremiumCode = selectedItem.inputPremiumCode;
        this.selectOnDataTable[rowIndex].price_discountPremium = selectedItem.price_discountPremium;
        this.selectOnDataTable[rowIndex].discount_percentagePremium = selectedItem.discount_percentagePremium;
        const ids = new Set(); 

        this.selectOnDataTable.forEach((item, index) => {
          if (item.selectedOfferName && Object.keys(item.selectedOfferName).length > 0) {
             if (ids.has(item.id)) {
              EventBus.$emit('show-toast', {
                  message:`Item Id: ${item.id} have already been added.`,
                  type: 'error',
                  });
                  this.selectOnDataTable.splice(index, 1);
                  return 
              } else {
                  ids.add(item.id); 
              }
          } 
        });
        this.updateDiscountPrices()
      }
    },

    codePremium(event,index) {
    const code = event.target.value;
    this.selectOnDataTable[index].inputPremiumCode = code;
    },

    afterDiscoundPremium(event, item, index) {
      const discount = parseFloat(event.target.value) || 0;
      this.selectOnDataTable[index].price_discountPremium = discount;

      if (discount > 0) {
        let discountPercentage = ((item.price_original - discount) / item.price_original) * 100;
        if (discountPercentage < 0) {
          discountPercentage = 0;
        } else if (discountPercentage > 100) {
          discountPercentage = 100;
        }

        item.discount_percentagePremium = discountPercentage.toFixed();
      } else {
        item.discount_percentagePremium = 0;
      }
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    numberFormatIdr(money) {
      return new Intl.NumberFormat(
        'id-ID',
        {
          style: 'currency',
          currency: 'IDR'
        }
      ).format(money);
    },
    numberFormatUsd(money) {
      return new Intl.NumberFormat(
        'en-US',
        {
          style: 'currency',
          currency: 'USD'
        }
      ).format(money);
    },
    cancel() {
      this.$router.push('/discounts');
    },
    deleteRow(index) {
      this.selectOnDataTable.splice(index, 1);
    },
    showPreviewFormat(value, field, currency) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        const money = (value == null) ? 0 : value;
        switch (currency) {
          case 'idr':
            this.previewFormat[field] = this.numberFormatIdr(money);
            break;
          case 'usd':
            this.previewFormat[field] = this.numberFormatUsd(money);
            break;
          default:
            break;
        }
      }, 1000);
    },
  }
};
</script>
<style scoped>
.tab-container {
  margin: 0 auto;
}

.tab-header {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 0.5rem;
}

.tab-item {
  color: #4b5563;
}

.tab-item.text-blue-500 {
  color: #2563eb;
}

.border-custom {
  border: 1px solid #3b82f6 !important;
}

.tab-container .tab-content .multiselect {
  width: 99%;
}

.tab-content {
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  background-color: #f9fafb;
}

.button-active-inactive {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.multiselect.isdiscounterror .multiselect__input,
.multiselect.isdiscounterror .multiselect__single {
  background-color: #fed7d7 !important;
  /* bg-red-200 */
}

/* Gaya untuk placeholder merah */
.multiselect.placeholder-red-500 .multiselect__input::placeholder {
  color: #f56565;
  /* placeholder-red-500 */
}
</style>